// import { Modal } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
// import HomeProjectPopUp from './projectCardPopUp/HomeProjectPopUp';
// import { HashLink as Link } from 'react-router-hash-link';
import { useInView } from 'react-intersection-observer';
import 'animate.css';



function ProjectCard({ id,imgSrc, location, title,plantPrice,adoptPrice,link }) {
  // const [isModalVisible, setIsModalVisible] = useState(false);



  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.2, // Trigger when 20% of the component is in view
  });

  return (
    <div ref={ref} className={`project-item ${inView ? 'animate__animated animate__fadeInUp' : ''}`} data-wow-duration="1000ms">
      <div className="project-item-inner">
        <div className="project-img middle-shade">
          <img src={imgSrc} alt={location} />
        </div>
        <div className="project-text">
          <span>{location}</span>
          <h2>{title}</h2>
          <Link to={`/ProjectDetails/${link}`}>
            <i className="icon-arrow-right"></i>
          </Link>
        </div>
      </div>

      
      {/* <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        style={{ top: 20, background: 'transparent', boxShadow: 'none' }}
        className="customModal"
        closable={false}
      >
        <HomeProjectPopUp
          closeModal={handleCancel}
          name={title}
          location={location}
          price={price}
          src={imgSrc}
          decription={decription}
          target={target}
          achieved={achieved}
        />
      </Modal> */}
    </div>
  );
}

export default ProjectCard;
