import React, { useContext, useEffect } from 'react'
import ProjectDetailPage from '../component/ProjectDetailPage/ProjectDetailPage';
import { GlobalLoaderContext } from '../Loader/GlobalLoader';
import Header from '../component/Header';
import Footer from '../component/Footer';

function ProjectDetails() {
    const { showLoader, hideLoader } = useContext(GlobalLoaderContext);
    useEffect(() => {
        showLoader();
        const timer = setTimeout(() => {
            hideLoader();
        }, 2000); 

        return () => clearTimeout(timer);
    }, [showLoader, hideLoader]);
   
    return (
        <>
            <div class="page-wrap">
                <Header />
                <ProjectDetailPage />
            </div>
            <Footer id="contact" />
        </>
    )
}

export default ProjectDetails