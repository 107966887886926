import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'
import projectReducer from './projectSlice'
import orderReducer from './orderSlice'
// import cartReducer from './cartSlice'



export const store = configureStore({
  reducer: {
    userReducer:userReducer,
    projectReducer:projectReducer,
    orderReducer:orderReducer
    // cartReducer:cartReducer
  },
});

export default store;
