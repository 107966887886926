import React from 'react'
// import { FaLeaf, FaTree, FaHandHoldingHeart } from 'react-icons/fa';
import styles from './styles.module.css'
import { Carousel } from 'antd';
import SpaIcon from '@mui/icons-material/Spa';


function About({ id }) {


    // const [counts, setCounts] = useState({ leaf: 0, tree: 0, heart: 0 });
    // const [startCounting, setStartCounting] = useState(false);
    // const sectionRef = useRef(null);

    // const maxCounts = { leaf: 681, tree: 80, heart: 100000000 };
    // const duration = 5000;
    // const intervalTime = 10;
    // console.log('counts', counts)
    // const increments = {
    //     leaf: maxCounts.leaf / (duration / intervalTime),
    //     tree: maxCounts.tree / (duration / intervalTime),
    //     heart: maxCounts.heart / (duration / intervalTime),
    // };

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setStartCounting(true);
    //                 observer.disconnect();
    //             }
    //         },
    //         { threshold: 0.5 }
    //     );

    //     if (sectionRef.current) {
    //         observer.observe(sectionRef.current);
    //     }

    //     return () => observer.disconnect();
    // }, []);
    // useEffect(() => {
    //     if (startCounting) {
    //         const timer = setInterval(() => {
    //             setCounts(prevCounts => ({
    //                 leaf: Math.min(prevCounts.leaf + increments.leaf, maxCounts.leaf),
    //                 tree: Math.min(prevCounts.tree + increments.tree, maxCounts.tree),
    //                 heart: Math.min(prevCounts.heart + increments.heart, maxCounts.heart),
    //             }));
    //         }, intervalTime);

    //         return () => clearInterval(timer);
    //     }
    // }, [startCounting, increments.leaf, increments.tree, increments.heart, maxCounts.leaf, maxCounts.tree, maxCounts.heart]);

    const partnerData = [
        {
            title: 'Direct Impact and Community Support',
            description: `Partnering with us ensures that 100% of your contribution goes directly into tree planting, ongoing care,
and providing jobs for underprivileged communities. Every dollar you invest fosters sustainable growth
and empowers local people to create lasting change.`,
            listPoint: [
                '100% Impact : All funds directly support tree planting and care',
                'Community Empowerment : Create jobs for underprivileged communities',
                'Sustainable Development : Invest in long-term ecological growth.'
            ],
            images: [
                '/assets/images/whyPartner/volunteers-treePlant.jpg',
                '/assets/images/whyPartner/treePlant-sample.jpg',
                '/assets/images/whyPartner/tree-care.jpg',
                
            ]
        },
        {
            title: 'Transparent Tracking and Engagement',
            description: `Every tree you plant contributes to a cleaner, healthier city. By planting trees, you can help reduce urban
            heat, improve air quality, and support local biodiversity. Take action today to combat pollution and build a
            sustainable urban environment for future generations.`,
            listPoint: [
                'Adoption Certificate : Receive a personalized tree adoption certificate.',
                'Real-Time Tracking : Monitor your tree’s growth via our portal.',
                'Engagement : Experience the impact firsthand with transparent updates.'
            ],
            images: [
                '/assets/images/crisis/smog.jpg',
                '/assets/images/crisis/view-trees.jpg',

            ]
        }
    ];

    return (
        <>




<div className={`container ptb-120 ${styles.partneraboutArea}`}>
            <h1 className={styles.title}>Why Partner with Us ?</h1>
                <Carousel dots={false} arrows >
                    {partnerData.map((item, index) => (
                        <div key={index} className={`carousel-slide ${styles.carouselSlide}`}>
                            <div className={`container ${styles.contentContainer}`}>
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 textContent">
                                        <h2 className={styles.crisistitleStyle}>{item.title}</h2>
                                        <p className={styles.crisistextStyless}>{item.description}</p>
                                        <ul className={styles.partnerlistPoints}>
                                                    {item.listPoint.map((point, idx) => (
                                                        <li key={idx}>
                                                            <SpaIcon className={styles.abouticon} />
                                                            {point}
                                                        </li>
                                                    ))}
                                                </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-12 imageCarousel">
                                        <Carousel autoplay dots={true}>
                                            {item.images.map((image, imgIndex) => (
                                                <div key={imgIndex}>
                                                    <img
                                                        src={image}
                                                        alt={`${item.title} ${imgIndex + 1}`}
                                                        className={`img-fluid ${styles.carouselImage}`}
                                                    />
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>


        {/* -------------------------------- */}

            {/* <section className={`${styles.partneraboutArea}`} id={id}>
            <h1 className={styles.title}>Why Partner With Us ?</h1>
                   
                <div className="container">
                    <div className={`row ${styles.aboutRowContainer}`}>
                        <Carousel dots={false} arrows={true}>
                            {partnerData.map((item, index) => (
                                <div key={index} className="col-lg-12">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-lg-6 col-md-12">
                                            <div className={styles.partnercontentContainer}>
                                                <h3 className={styles.partnertitle}>{item.title}</h3>
                                                <p className={styles.partnerdescription}>{item.description}</p>
                                                <ul className={styles.partnerlistPoints}>
                                                    {item.listPoint.map((point, idx) => (
                                                        <li key={idx}>
                                                         
                                                            <SpaIcon className={styles.icon} />
                                                            {point}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className={styles.partnercarouselContainer}>
                                                <Carousel autoplay dots={false} className={styles.partnercarousel}>
                                                    {item.images.map((image, imgIndex) => (
                                                        <div key={imgIndex}>
                                                            <img
                                                                src={image}
                                                                alt={`${item.title} ${imgIndex + 1}`}
                                                                className={`img-fluid ${styles.partnercarouselImage} ${styles.partnercarouselImage3D}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default About