import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
// import { NavLink as Link } from 'react-router-dom';
import { fetchEndUserInfo, logout } from '../store/userSlice';
import Notification from './notification/Notification';
import Localstorage from '../utils/storage/Localstorage';


function Header() {
    const navigate = useNavigate();
    // const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch()
    const [isSticky, setIsSticky] = useState(false); // State to handle sticky header
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const token = Localstorage.JWT_TOKEN.get();
        setAuth(token);
    }, []);

    const userInfo = useSelector((state) => state.userReducer.userInfo)
    // console.log('userInfo????', userInfo)
    useEffect(() => {
        if (auth && !userInfo) {
            dispatch(fetchEndUserInfo())
        }
    }, [dispatch, auth, userInfo])

    const handleLogoClick = (event) => {
        event.preventDefault();
        navigate('/', { replace: true });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const toggleDropdown = () => {
        console.log('dropdownOpen***', dropdownOpen)
        // setDropdownOpen(!dropdownOpen);
        setDropdownOpen((prev) => !prev);
        if (mobileMenuOpen) setMobileMenuOpen(false);
    };
    const toggleMobileMenu = () => {
        // setMobileMenuOpen(!mobileMenuOpen);
        setMobileMenuOpen((prev) => !prev);
        if (dropdownOpen) setDropdownOpen(false);
    };

    const handleLogout = () => {
        try {
            //   dispatch(changeLoadingState(true));
            Localstorage.clear();
            dispatch(logout())
            Notification({ message: "Logout Successfully", type: "success" });
            navigate('/');
        } catch (error) {
            console.log(error);
            Notification({ message: "Something went wrong", type: "error" });
        }
        // finally {
        //   dispatch(changeLoadingState(false));
        // }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
            setMobileMenuOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle menu item click to close mobile menu
    const handleMenuItemClick = () => {
        setMobileMenuOpen(false);
    };

    return (
        <>

            <header className={`cp-header-area cp-header-style-2 ${isSticky ? 'stickyNavbar' : ''}`} id="header">
                <div id="cp-header-sticky" className="menu-area">
                    <div className="container-fluid">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2 col-md-3 col-5">
                                    <div className="logo">
                                        <Link to="" onClick={handleLogoClick}>
                                            <img src="/assets/images/treetology-logo.png" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-10 col-lg-10 col-md-9 col-7 d-flex justify-content-end align-items-center">
                                    <div className="main-menu d-none d-lg-block">
                                        <nav id="mobile-menu">
                                            <ul className="nav">
                                                <li><Link to="/#home">Home</Link></li>
                                                <li><Link to="/#about">About Us</Link></li>
                                                <li><Link to="/#our-projects">Our Projects</Link></li>
                                                <li><Link to="/#howItWorks">How It Works</Link></li>
                                                <li><Link to="/#testimonials">Testimonials</Link></li>
                                                {/* <li><Link smooth to="/#contact">Contact</Link></li> */}
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="cp-header-area-right">
                                        <ul style={{ marginBottom: '0' }}>
                                            <li style={{ marginRight: '1rem' }}><Link smooth to="/#our-projects" className="ch-btn-style-2 d-none d-lg-block plantBtnpadding">Plant With Us Today</Link></li>
                                            <li>
                                                <button className="user-btn" onClick={toggleDropdown}>
                                                    <i className="fa fa-bars menu-icon"></i>
                                                    <i className="fa fa-user user-icon"></i>
                                                </button>
                                                {dropdownOpen && (
                                                    <div ref={dropdownRef} className="user-dropdown">
                                                        {(!userInfo || Object.keys(userInfo).length === 0) && (
                                                            <Link to="/login"><i className="fa fa-sign-in faIcon"></i> Login/Register</Link>
                                                        )}
                                                        {(userInfo && Object.keys(userInfo).length > 0) && (
                                                            <>
                                                                <Link to="/myProfile"><i className="fa fa-user user-icon-1 faIcon"></i> My Profile</Link>
                                                                {/* <Link to="/myActivity"><i className="fa fa-tree faIcon"></i> My Activity</Link> */}
                                                                <Link to="/my-order"><i className="fa fa-tree faIcon"></i> My Order</Link>
                                                                <Link to="/my-gift"><i className="fa fa-tree faIcon"></i> My Gifts</Link>
                                                                <Link to="/#" onClick={handleLogout}><i className="fa fa-power-off logout-icon faIcon"></i> Logout</Link>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                    <button className="mobile-menu-toggle d-lg-none" onClick={toggleMobileMenu}>
                                        <i className={`fa ${mobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                                    </button>
                                    <div className={`mobile-menu d-lg-none ${mobileMenuOpen ? 'open' : ''}`}>
                                        <ul>
                                            <li><Link to="/#home" onClick={handleMenuItemClick}>Home</Link></li>
                                            <li><Link to="/#about" onClick={handleMenuItemClick}>About Us</Link></li>
                                            <li><Link to="/#our-projects" onClick={handleMenuItemClick}>Our Projects</Link></li>
                                            <li><Link to="/#howItWorks" onClick={handleMenuItemClick}>How It Works</Link></li>
                                            <li><Link to="/#testimonials" onClick={handleMenuItemClick}>Testimonials</Link></li>
                                            <li><Link to="/#contact" onClick={handleMenuItemClick}>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header