import React from 'react'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';


export default function Footer({ id }) {
    return (
        <>
            <footer className="footer-section" id={id}>
                <div className="upper-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget about-widget">
                                    <div className="logo widget-title">
                                        <img src="/assets/images/treetology-logo.png" alt="logo" />
                                    </div>
                                    {/* <p>Desires to obtain pain of itself, because it is pain, but occasionally
                                        circumstances.</p> */}
                                    {/* <div className="widget social-widget p-0">
                                        <ul>
                                            <li><Link to="#"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="ti-instagram"></i></Link></li>
                                            <li><Link to="#"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="#"><i className="ti-pinterest"></i></Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><Link smooth to="/#home"> Home</Link></li>
                                <li><Link smooth to="/#about">About Us</Link></li>
                                <li><Link smooth to="/#our-projects">Our Projects</Link></li>
                                <li><Link smooth to="/#howItWorks">How It Works</Link></li>
                                <li><Link smooth to="/#testimonials">Testimonials</Link></li>
                            </ul>
                        </div>
                    </div> */}
                            <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li><Link smooth to="/terms"> Terms of service</Link></li>
                                        <li><Link smooth to="/refund">Return and refund policy</Link></li>
                                        <li><Link smooth to="/privacy-policy">Privacy policy</Link></li>
                                        <li><Link smooth to="/cookie-policy">Cookie policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget contact-widget">
                                    <div className="widget-title">
                                        <h3>Office Information</h3>
                                    </div>
                                    <div className="contact-ft">
                                        <p>Novel Office, Graphite India Main Rd, Industrial Area, Whitefield, Bengaluru, India, 560048</p>
                                        <ul>
                                            <li><i className="icon-email-1"></i>info@treetology.com</li>
                                            <li><i className="icon-call-2"></i>+971522753210</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget subscribe">
                                    <div className="widget-title">
                                        <h3>Social Media</h3>
                                    </div>
                                    {/* <form action="#">
                                        <div className="form-field">
                                            <input type="email" placeholder="Your Email..." id="emails" required />
                                            <button type="submit">Subscribe</button>
                                        </div>
                                    </form> */}


                                    <div className="widget social-widget">
                                        <ul>
                                            <li>
                                                <Link to="https://www.facebook.com/share/ndoFJxNFkbdGztUC/?mibextid=qi2Omg" target="_blank"><i className="ti-facebook"></i></Link>
                                            </li>

                                            <li>
                                                <Link to="https://www.instagram.com/treetology?igsh=MnhyOXJlbHV5bHpj" target="_blank"><i className="ti-instagram"></i></Link>
                                            </li>

                                            <li>
                                                <Link to="https://x.com/treetology?t=vmtdPr91urZFQzd4nrzu0g&s=09" target="_blank">
                                                    {/* <i className="fab fa-x-twitter"></i> */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 1.646a.5.5 0 0 1 .708 0L8 7.293 13.646 1.646a.5.5 0 0 1 .708.708L8.707 8l5.647 5.646a.5.5 0 0 1-.708.708L8 8.707l-5.646 5.647a.5.5 0 0 1-.708-.708L7.293 8 1.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="https://www.linkedin.com/company/treetology/" target="_blank"><i className="ti-linkedin"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lower-footer">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col col-lg-12 col-12">
                                <div className="copy-right text-center">
                                    <p className="copyright">Copyright  2024 <Link to="index.html">Treetology</Link>, All
                                        Rights
                                        Reserved.</p>
                                </div>
                            </div>
                            {/* <div className="col col-lg-6 col-12">
                                <ul className="lower-footer-link">
                                    <li><Link to="/terms">Terms & Condition</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
