import React, { useEffect, useState } from 'react'
// import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjectWithoutGift } from '../../store/orderSlice';
import { fetchEndUserInfo } from '../../store/userSlice';
import { Button, Table } from 'antd';
import { usePDF } from "react-to-pdf";
import Certificate from '../../certificateComp/Certificate';

function ProjectOrderDataTable() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const ProjectOrder = useSelector((state) => state.orderReducer.projectOrderWithoutGift);

    const { toPDF, targetRef } = usePDF();
    const [certificateData, setCertificateData] = useState(null);

    useEffect(() => {
        if (userInfo?._id) {
            dispatch(fetchProjectWithoutGift(userInfo?._id))
        }
    }, [dispatch, userInfo?._id])

    useEffect(() => {
        if (!userInfo._id) {
            dispatch(fetchEndUserInfo(userInfo?._id));
        }
    }, [dispatch, userInfo?._id])

    const handleDownloadCertificate = (order) => {
        setCertificateData(order);
        setTimeout(() => {
            toPDF({
                filename: `${order?.projectName}-${order?.purchasedDate}-certificate.pdf`,
                page: {
                    format: 'letter',
                    orientation: 'landscape',
                },
            });
        }, 0);
    };

    // console.log("certificateDatacertificateData", certificateData)

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                // console.log("record", record),
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        width={50}
                        src={record?.img}  // Assuming you have the project image URL
                        alt={text}
                        style={{ marginRight: 8 }}
                    />
                    {text}
                </div>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total',
            key: 'total',
            //   render: (text) => `₹${text}`,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Purchased Date',
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (date) => new Date(date).toLocaleString(),  // Formatting the date
        },
        {
            title: 'Download Certificate',
            key: 'downloadCertificate',
            render: (text, record) => (
                <Button
                    type="default"
                    onClick={() => handleDownloadCertificate(record)}
                >
                    Download Certificate
                </Button>
            ),
        },

    ];

    const dataSource = ProjectOrder.map((order, index) => ({
        key: index,
        projectName: order?.plants[0]?.projectId?.name,
        location: order?.plants[0]?.projectId?.location,
        quantity: order?.plants[0]?.quantity,
        img: process.env.REACT_APP_BACKEND_URL + "/" + order?.plants[0]?.projectId?.images[0],
        orderNumber: order.orderNumber,
        phoneNumber: order.phoneNumber,
        paymentMethod: order.paymentMethod,
        total: order?.total,
        type: order?.plants[0]?.selectType,
        status: order.status,
        purchasedDate: order.purchasedDate,
        userId: order.userId,
    }));


    // useEffect(() => {
    //     console.log('certificateRef:', certificateRef.current);
    // }, [certificateData]);


    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                //    pagination={false}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                }}
            />

            {/* Ensure Certificate component is rendered conditionally */}
            {/* {certificateData && (
                <div ref={certificateRef} style={{ display: 'none' }}>
                    <Certificate user={userInfo} data={certificateData} />
                </div>
            )} */}
            {/* Hidden Certificate component for PDF generation */}
            {/* <div style={{ display: 'none' }}>
                <PDFExport ref={pdfRef} fileName="certificate.pdf">
                    <Certificate user={userInfo} data={selectedRecord} />
                </PDFExport>
            </div> */}

            {
                certificateData && (
                    <Certificate ref={targetRef} user={userInfo} data={certificateData} />
                )
            }

        </>
    )
}

export default ProjectOrderDataTable