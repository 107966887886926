import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectWithGift } from '../../store/orderSlice';
import { fetchEndUserInfo } from '../../store/userSlice';
import { Button, Table } from 'antd';
import { usePDF } from "react-to-pdf";
import GiftCertificate from '../../certificateComp/GiftCertificate';

function ProjectOrderWithGiftDataTable() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const ProjectGiftOrder = useSelector((state) => state.orderReducer.projectOrderWithGift);
    // console.log("ProjectGiftOrder", ProjectGiftOrder)

    const { toPDF, targetRef } = usePDF();
    const [certificateData, setCertificateData] = useState(null);

    useEffect(() => {
        dispatch(fetchProjectWithGift(userInfo?._id))
    }, [dispatch, userInfo?._id])

    useEffect(() => {
        if (!userInfo._id) {
            dispatch(fetchEndUserInfo(userInfo?._id));
        }
    }, [dispatch, userInfo._id])


    const handleDownloadCertificate = (order) => {
        setCertificateData(order);
        setTimeout(() => {
            toPDF({
                filename: `${order?.projectName}-certificate.pdf`,
                page: {
                    format: 'letter',
                    orientation: 'landscape',
                },
            });
        }, 0);
    };

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        width={50}
                        src={record?.img}  // Assuming you have the project image URL
                        alt={text}
                        style={{ marginRight: 8 }}
                    />
                    {text}
                </div>
            ),
        },
        {
            title: 'Gifted By',
            dataIndex: 'giftBy',
            key: 'giftBy',
        },
        {
            title: 'Reciever Email',
            dataIndex: 'receiverEmail',
            key: 'receiverEmail',
        },
        {
            title: 'Occasion',
            dataIndex: 'occasion',
            key: 'occasion',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total',
            key: 'total',
            //   render: (text) => `₹${text}`,
        },
        // {
        //   title: 'Type',
        //   dataIndex: 'type',
        //   key: 'type',
        // },
        {
            title: 'Purchased Date',
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (date) => new Date(date).toLocaleString(),  // Formatting the date
        },
        {
            title: 'Download Certificate',
            key: 'downloadCertificate',
            render: (text, record) => (
                <Button
                    type="default"
                    onClick={() => handleDownloadCertificate(record)}
                >
                    Download Certificate
                </Button>
            ),
        },
    ];

    const dataSource = ProjectGiftOrder.map((order, index) => ({
        key: index,
        projectName: order?.plants[0]?.projectId?.name,
        giftBy: order?.userId?.name,
        location:order?.plants[0]?.projectId?.location,
        // quantity:order?.plants[0]?.quantity,
        receiverEmail: order?.plants[0]?.giftId?.receiverEmail,
        receiverName: order?.plants[0]?.giftId?.receiverName,
        occasion: order?.plants[0]?.giftId?.Occasion,
        mobileNumber: order?.plants[0]?.giftId?.mobileNumber,
        quantity: order?.plants[0]?.quantity,
        img: process.env.REACT_APP_BACKEND_URL + "/" + order?.plants[0]?.projectId?.images[0],
        // orderNumber: order.orderNumber,
        // phoneNumber: order.phoneNumber,
        // paymentMethod: order.paymentMethod,
        total: order?.total,
        // type:order?.plants[0]?.selectType,
        // status: order.status,
        purchasedDate: order.purchasedDate,
        // userId: order.userId,
    }));
    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                //    pagination={false}
                pagination={{
                    pageSize: 10, // Number of items per page
                    showSizeChanger: true, // Allow user to change the page size
                    pageSizeOptions: ['10', '20', '30', '40'], // Page size options
                }}
            />

            {/* {
                certificateData && (
                    <Certificate ref={targetRef} user={userInfo} data={certificateData} />
                )
            } */}
            {
                certificateData && (
                    <GiftCertificate ref={targetRef} user={userInfo} data={certificateData} />
                )
            }

        </>
    )
}

export default ProjectOrderWithGiftDataTable